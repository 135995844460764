<template>
  <div class="row">
    <div class="col-12 g text-center" v-if="step == 0">
        كيف تريد اضافة الطلاب؟
    </div>
    <div class="col-12 col-lg-9 c">
        <div class="row">
            <div class="col-12 col-lg-4 g text-center ii" v-if="step == 0" @click="step=1">
                <div class="card card-body">
                    <img :src="require('@/assets/images/add-way-1.png')" alt="">
                    <h4>
                        اضافة الطلاب يدوياً
                    </h4>
                </div>
            </div>
            <div class="col-12 col-lg-4 g text-center ii" v-if="step == 0" @click="step=2; listenNoor()">
                <div class="card card-body">
                    <img :src="require('@/assets/images/noor.jpg')" alt="">
                    <h4>
                        استيراد الطلاب من نور
                    </h4>
                </div>
            </div>
            <div class="col-12 col-lg-4 g text-center ii" v-if="step == 0" @click="step=3; listenNoor2()">
                <div class="card card-body">
                    <img :src="require('@/assets/images/noor2.jpg')" alt="">
                    <h4>
                        استيراد المسارات من ملف كشف الطلاب
                    </h4>
                </div>
            </div>
            <div class="col-12 col-lg-4 g text-center ii" v-if="step == 0" @click="step=4; listenNoor3()">
                <div class="card card-body">
                    <img :src="require('@/assets/images/excel.png')" alt="">
                    <h4>
                        استيراد من ملف اكسل
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" v-if="students.length"></div>
    <div class="col-12 c g col-lg-6" v-if="students.length">
        <div class="card card-body">
            <div class="form-group">
              <label for="">اختر مجموعة الاعدادات</label>
              <select class="form-control" v-model="group_id">
                <option v-for="group in groups" :key="group._id" :value="group._id">
                    {{ group.title }}
                </option>
              </select>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="addNow()">
                    <span v-if="!loading">اضافة الطلاب الآن</span>
                    <span v-if="loading">جاري الاضافة...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12" v-if="students.length"></div>
    <div class="col-12 col-lg-6 c g" v-if="step==1">
        <div class="card card-body">
            <div class="form-group">
              <label for="">الاسم</label>
              <input type="text"
                class="form-control" v-model="student.name">
            </div>
            <div class="form-group">
              <label for="">رقم الهوية</label>
              <input type="text"
                class="form-control" v-model="student.number">
            </div>
            <div class="form-group">
              <label for="">الجوال <i class="fa fa-plus text-primary" @click="phonespush()"></i></label>
              <div v-for="(find, index) in phones"  :key="index" class="g">
                    <input v-model="find.value" class="form-control" @change="refreshPhones()">
                </div>
            </div>
            <div class="form-group">
              <label for="">اختر مجموعة الاعدادات</label>
              <select class="form-control" v-model="student.group_id">
                <option v-for="group in groups" :key="group._id" :value="group._id">
                    {{ group.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">اختر الصف (<a href="javascript:;" @click="custom = true"><i class="fa fa-plus"></i> اضافة صف/فصل جديد</a>)</label>
              <select class="form-control" v-model="student.classname" @change="cc()" v-if="!custom">
                <option v-for="c in classes" :key="c._id" :value="c.title">
                    {{ c.title }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="student.classname" placeholder="اكتب الصف يدوياً..." v-if="custom">
            </div>
            <div class="form-group">
              <label for="">اختر الفصل</label>
              <select class="form-control" v-model="student.classroom" v-if="!custom">
                <option v-for="c in classrooms" :key="c" :value="c">
                    {{ c }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="student.classroom" placeholder="اكتب الفصل يدوياً..." v-if="custom">
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="addNowSingle()">
                    <span v-if="!loading">اضافة الطالب الآن</span>
                    <span v-if="loading">جاري الاضافة...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 c g" v-if="step==2 && !students.length">
        <div class="card card-body">
            ملف الإرشاد الطلابي/كشف الطلاب من نظام نور
            <br /><br />
            <input
                id="ingredient_file"
                type="file"
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
            <br><br><br>
            <center>
                <strong style='color: green'>*بدلاً من رفع الملف يمكنك استيراد البيانات تلقائياً من نظام نور اذا قمت بتثبيت اداة التحضير الذكي على المتصفح* <br><br><a href="javscript:;" @click="$router.push('/extension')"><i class="fa fa-check-square"></i> اضغط هنا للتثبيت</a></strong>
            </center>
        </div>
    </div>
    <div class="col-12 col-lg-6 c g" v-if="step==3 && !students.length">
        <div class="card card-body">
            <div class="alert alert-warning text-center">
                يرجى تصدير كل مسار في ملف مستقل.
            </div>
            ملف كشف الطلاب حسب المسار
            <br /><br />
            <input
                id="ingredient_file"
                type="file"
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 c g" v-if="step==4 && !students.length">
        <div class="card card-body">
            <div class="alert alert-warning text-center">
                ارفق ملف الاكسل يحتوى على رقم الجوال والاسم ورقم الهوية
            </div>
            مثال: <img :src="require('@/assets/images/import-excel-example.png')" style="width: 100%">
            <hr />
            <div class="form-group">
              <label for="">اختر الصف (<a href="javascript:;" @click="custom = true"><i class="fa fa-plus"></i> اضافة صف/فصل جديد</a>)</label>
              <select class="form-control" v-model="classname" @change="cc()" v-if="!custom">
                <option v-for="c in classes" :key="c._id" :value="c.title">
                    {{ c.title }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="classname" placeholder="اكتب الصف يدوياً..." v-if="custom">
            </div>
            <div class="form-group">
              <label for="">اختر الفصل</label>
              <select class="form-control" v-model="classroom" v-if="!custom">
                <option v-for="c in classrooms" :key="c" :value="c">
                    {{ c }}
                </option>
              </select>
              <input type="text" class="form-control" v-model="classroom" placeholder="اكتب الفصل يدوياً..." v-if="custom">
            </div>
            <br /><br />
            <input
                id="ingredient_file"
                type="file"
            />
            <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
            </div>
        </div>
    </div>
    <div class="col-12 g" v-if="students.length">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-4 c g">
                    <div class="form-group">
                      <label for="">اذا كان الطالب مسجل مسبقاً</label>
                      <select class="form-control" v-model="on_exists">
                        <option value="nothing">لا شيئ</option>
                        <option value="phone">تحديث رقم الجوال</option>
                        <option value="class">تحديث الصف والفصل</option>
                        <option value="name">تحديث الاسم</option>
                      </select>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="yes" v-model="replace0">
                        استبدال الصفر في اول رقم الجوال برقم 9.
                      </label>
                    </div>
                </div>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهوية
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            الصف
                        </th>
                        <th>
                            الفصل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="student in students" :key="student.name">
                            <td>
                                {{ student.name }}
                            </td>
                            <td>
                                {{ student.number }}
                            </td>
                            <td>
                                {{ student.phones.join(",") }}
                            </td>
                            <td>
                                {{ student.classname }}
                            </td>
                            <td>
                                {{ student.classroom }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
var XLSX = require("xlsx");
export default {
    data(){
        return {
            loading: true,
            user: JSON.parse(localStorage.getItem('user')),
            step: 0,
            students: [],
            groups: [],
            group_id: "",
            student: {},
            custom: false,
            classes: [],
            classrooms: [],
            classname: "",
            selected_class: {},
            classroom: "",
            phones: [{value:""}],
            tmpx: null,
            on_exists: "nothing",
            replace0: "yes"
        }
    },
    created(){
        if(!checkPer("students|students|add")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/groups', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.groups = r.response
                g.group_id = g.groups[0]._id
                g.student.group_id = g.groups[0]._id
            }
        }).fail(function(){
        })
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
                g.student.classname = g.classes[0]?.title ?? ""
                g.classrooms = g.classes[0]?.classrooms ?? []
                g.student.classroom = g.classes[0]?.classrooms[0] ?? ""
            }
        }).fail(function(){
        })
    },
    methods: {
        cc(){
            var g = this
            g.classes.forEach(function(a){
                if(a.title == g.student.classname){
                    g.classrooms = a.classrooms
                }
            })
        },
        listenNoor() {
        var g = this;
        var Upload = function (file) {
            this.file = file;
        };

        Upload.prototype.getType = function () {
            return this.file.type;
        };
        Upload.prototype.getSize = function () {
            return this.file.size;
        };
        Upload.prototype.getName = function () {
            return this.file.name;
        };
        Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());
            formData.append("upload_file", true);

            $.ajax({
            type: "POST",
            url: "https://private-api.brmja.com/excel",
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                );
                }
                return myXhr;
            },
            success: function (data) {
                // your callback here
                $("#progress-wrp").fadeOut("slow");
                data.forEach(function (student) {
                if (student[5]) {
                    student[5] = student[5].toString();
                    if (
                    parseInt(student[5]) != "NanN" &&
                    student[5].trim() != "" &&
                    !student[5].includes("ا")
                    ) {
                    g.students.push({
                        name: student[4],
                        phones: [student[1]],
                        number: student[5],
                        classname: student[3],
                        classroom: student[2]
                    });
                    }
                }
                });
            },
            error: function (error) {
                // handle error
                alert("حدث خطأ");
            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000,
            });
        };

        Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
            percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
        };
        setTimeout(() => {
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);

            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            upload.doUpload();
            });
        }, 1000);
        },
        listenNoor2() {

        var g = this;
        var Upload = function (file) {
            this.file = file;
        };

        Upload.prototype.getType = function () {
            return this.file.type;
        };
        Upload.prototype.getSize = function () {
            return this.file.size;
        };
        Upload.prototype.getName = function () {
            return this.file.name;
        };
        Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());
            formData.append("upload_file", true);
            formData.append("original", true);

            $.ajax({
            type: "POST",
            url: "https://private-api.brmja.com/excel",
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                );
                }
                return myXhr;
            },
            success: function (data) {
                // your callback here
                $("#progress-wrp").fadeOut("slow");
                data.forEach(function(page){
                    var classname = page[9][4] + ' - ' + page[5][4];
                    var classroom = page[13][4];
                    page.forEach(function (student) {
                        if (student[20]) {
                            student[20] = student[20].toString();
                            if (
                            parseInt(student[20]) != "NanN" &&
                            student[20].trim() != "" &&
                            !student[20].includes("ا")
                            ) {
                            g.students.push({
                                name: student[28],
                                phones: [student[2]],
                                number: student[20],
                                classname: classname,
                                classroom: classroom
                            });
                            }
                        }
                        });
                })
                console.log(g.students)
            },
            error: function (error) {
                // handle error
                alert("حدث خطأ");
            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000,
            });
        };

        Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
            percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
        };
        setTimeout(() => {
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);
            
            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            if(file.name.includes(".xlsx")){
                g.tmpx = file
                alert(`عفواً صيغة ملف xlsx غير مدعومة.<br> لكن لا تقلق.. تم تحويل الملف الى الصيغة المطلوبة<br>برجاء تحميل الملف من خلال الزر التالي واعادة رفعه.
                <br><br>
                <button class='btn btn-success dow'>
                    <i class='fa fa-download'></i>
                    تحميل الملف المحول
                </button>
                `)
                setTimeout(function(){
                $(".dow").click(function(){
                    $("#alert-modal___BV_modal_header_ > button").click()
                    var reader = new FileReader();
                    reader.onload = function(e) {
                        var data = e.target.result;
                        var workbook = XLSX.read(data, {
                            type: 'binary'
                        });
                        XLSX.writeFile(workbook, g.tmpx.name.replace(".xlsx", ".xls"));
                    };
                    reader.readAsBinaryString(g.tmpx);
                })
                }, 100)
            }else{
                upload.doUpload();
            }
            });
        }, 1000);
        },
        listenNoor3() {

        var g = this;
        var Upload = function (file) {
            this.file = file;
        };

        Upload.prototype.getType = function () {
            return this.file.type;
        };
        Upload.prototype.getSize = function () {
            return this.file.size;
        };
        Upload.prototype.getName = function () {
            return this.file.name;
        };
        Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());
            formData.append("upload_file", true);
            formData.append("original", true);

            $.ajax({
            type: "POST",
            url: "https://private-api.brmja.com/excel",
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                );
                }
                return myXhr;
            },
            success: function (data) {
                // your callback here
                $("#progress-wrp").fadeOut("slow");
                data.forEach(function(page){
                    page.forEach(function(a){
                        if(a[1] != "الإسم" && a[1] != "الاسم"){
                            g.students.push({
                                name: a[1],
                                phones: [a[0]],
                                number: a[2],
                                classname: g.classname,
                                classroom: g.classroom
                            });
                        }
                    })
                })
            },
            error: function (error) {
                // handle error
                alert("حدث خطأ");
            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000,
            });
        };

        Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
            percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
        };
        setTimeout(() => {
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);
            
            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            if(file.name.includes(".xlsx")){
                g.tmpx = file
                alert(`عفواً صيغة ملف xlsx غير مدعومة.<br> لكن لا تقلق.. تم تحويل الملف الى الصيغة المطلوبة<br>برجاء تحميل الملف من خلال الزر التالي واعادة رفعه.
                <br><br>
                <button class='btn btn-success dow'>
                    <i class='fa fa-download'></i>
                    تحميل الملف المحول
                </button>
                `)
                setTimeout(function(){
                $(".dow").click(function(){
                    $("#alert-modal___BV_modal_header_ > button").click()
                    var reader = new FileReader();
                    reader.onload = function(e) {
                        var data = e.target.result;
                        var workbook = XLSX.read(data, {
                            type: 'binary'
                        });
                        XLSX.writeFile(workbook, g.tmpx.name.replace(".xlsx", ".xls"));
                    };
                    reader.readAsBinaryString(g.tmpx);
                })
                }, 100)
            }else{
                upload.doUpload();
            }
            });
        }, 1000);
        },
        addNow(){
            this.loading = true
            var g = this;
            $.post(api + '/user/students/add', {
                jwt: this.user.jwt,
                group_id: this.group_id,
                on_exists: this.on_exists,
                students: JSON.stringify(this.students),
                replace0: this.replace0 == true || this.replace0 == true ? 'yes' : false
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert(r.response, 100)
                }
            }).fail(function(){
                g.loading = false
            })
        },
        addNowSingle(){
            this.loading = true
            var g = this;
            var arr = [g.student]
            $.post(api + '/user/students/add', {
                jwt: this.user.jwt,
                group_id: this.group_id,
                students: JSON.stringify(arr)
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert(r.response, 100)
                    setTimeout(() => {
                        $("#alert-modal___BV_modal_header_ > button").click()
                    }, 3000);
                    g.student = {
                        group_id: g.student.group_id,
                        classname: g.student.classname,
                        classroom: g.student.classroom,
                        phones: [""]
                    }
                    g.phones = [""]
                }
            }).fail(function(){
                g.loading = false
            })
        },
        refreshPhones(){
            this.student.phones = this.phones.map(function(r){
                return r.value
            })
        },
        phonespush(){
            this.phones.push({
                value: ""
            })
        }
    }
}
</script>

<style>
.ii img{
    width: 60% !important;
    border-radius: 20px;
    margin: 0px auto;
}
.ii h4{
    padding: 10px;
}
.ii .card:hover{
    border: 1px solid green;
    background: #fafafa;
    cursor: pointer;
}
</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>